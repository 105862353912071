import React from 'react';
import { MDBCol, MDBContainer, MDBFooter, MDBRow } from 'mdbreact';

export default function App() {
  return (
    <MDBFooter bgColor='light' className='text-start text-lg-left'>
      <div className='text-start p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
      <MDBContainer>
            <MDBRow>
                <MDBCol size="12" style={{marginTop: "1rem"}}>
                <h5>Realização:</h5>
                </MDBCol>
                <MDBCol size='6' md='3' className='d-flex align-items-center'>
                    <img src='https://www.feevale.br/s/conteudo/16426948-d36c-4b5e-b0ee-136e22a13b85/logo-feevale.png' className='img-fluid shadow-4' alt='Feevale'/>
                </MDBCol>
                <MDBCol size='6' md='9'></MDBCol>
                <MDBCol size="12" style={{marginTop: "1rem"}}>
                <h5>Apoio:</h5>
                </MDBCol>
                <MDBCol size='6' md='3' className='d-flex align-items-center'>
                    <img src='https://www.feevale.br/s/conteudo/2d6dd65c-a322-46e3-b2ce-6e796b8a789d/mnc_25_anos_-_branco_hor.png' className='img-fluid shadow-4' alt='Feevale'/>
                </MDBCol>
                <MDBCol size='6' md='3' className='d-flex align-items-center'>
                    <img src='https://www.feevale.br/s/conteudo/a6f9fe1c-1013-4aba-a9f5-ce3f565f3464/Cultura.png' className='img-fluid shadow-4' alt='Feevale'/>
                </MDBCol>
                <MDBCol size='6' md='3' className='d-flex align-items-center'>
                    <img src='https://www.feevale.br/s/conteudo/9e57b6be-24a7-4593-b197-eac9a0499528/Logo_Bicentenario_Alemao_preto-e-reticula.png' className='img-fluid shadow-4' alt='Feevale'/>
                </MDBCol>
                <MDBCol size='6' md='3'></MDBCol>
            </MDBRow>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}