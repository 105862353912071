import React, { useState, useEffect } from "react";

import axios from "axios";

import { MDBDataTable } from "mdbreact";

import CardEvento from "../CardEvento/CardEvento";

export default function Eventos() {
	const [eventos, setEventos] = useState(null);

	useEffect(() => {
		axios
			.get(`https://servico.feevale.br/site/v2/imigracaoalema`)
			.then((res) => {
				res?.data?.map((a) => {
					if (
						Date.parse(new Date(a?.Configuracoes?.DataEvento?.Value)) <
						Date.parse(new Date(Date.now()))
					) {
						a.Configuracoes.DataEvento.Value = Date.parse(new Date(2099, 1, 1));
						a.Configuracoes.DataEventoText.Value = "Evento passado";
					} else {
						a.Configuracoes.DataEvento.Value = Date.parse(
							new Date(a?.Configuracoes?.DataEvento?.Value)
						);
					}
				});

				res.data = res.data.sort(
					(a, b) =>
						a?.Configuracoes?.DataEvento?.Value -
						b?.Configuracoes?.DataEvento?.Value
				);
				setEventos(res.data);
			});
	}, []);

	const data = {
		columns: [
			{
				label: "",
				field: "titulo",
				width: 200,
				attributes: {
					className: "d-none",
				},
			},
		],
		rows: eventos?.map((evento) => {
			evento.titulo = <CardEvento evento={evento} />;
			return evento;
		}),
	};

	return (
		<MDBDataTable
			borderless
			small
			info={false}
			searchBottom={false}
			data={data}
			entries={5}
			entriesOptions={[10, 20, 30]}
			infoLabel={["Mostrando resultados entre", "e", "de", "eventos"]}
			paginationLabel={["Anterior", "Próximo"]}
			noRecordsFoundLabel="Nenhum resultado encontrado"
			entriesLabel="Mostrar eventos"
			searchLabel="Procurar"
		/>
	);
}
