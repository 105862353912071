import React, { useState, useEffect } from "react";

import axios from "axios";

import { MDBIcon, MDBCard, MDBCardBody } from "mdbreact";

import { Swiper, SwiperSlide } from "swiper/react"; // Componentes Swiper
import { Autoplay, EffectCoverflow, Pagination, Navigation } from "swiper"; // Modulos Swiper
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/bundle";
import "swiper/css/virtual";
import "swiper/css/zoom";

export default function Noticias() {
	const [noticias, setNoticias] = useState(null);

	useEffect(() => {
		axios
			.get(`https://servico.feevale.br/site/v1/noticias/0/10/bicentenário2024`)
			.then((res) => {
				res.data = res.data.sort((a, b) => {
					return Date.parse(new Date(b?.dataCadastro)) - Date.parse(new Date(a?.dataCadastro));
				});
				setNoticias(res.data);
			});
	}, []);

	let breakpoints = {
		// when window width is >= 320px
		320: {
			slidesPerView: 1,
			navigation: false,
		},
		// when window width is >= 480px
		480: {
			slidesPerView: 2,
		},
		// when window width is >= 640px
		640: {
			slidesPerView: 3,
		},
	};

	return (
		<>
			<Swiper
				effect={"coverflow"}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={3}
				autoplay={true}
				spaceBetween={-40}
				style={{
					"--swiper-navigation-size": "25px",
				}}
				breakpoints={breakpoints}
				coverflowEffect={{
					rotate: 0,
					stretch: 0,
					slideShadows: false,
				}}
				freeMode={true}
				initialSlide={3}
				//loop={noticias.length > 3 ? true : false} // Buga se ativar
				navigation={true}
				cssMode={false}
				pagination={false}
				rewind={true}
				modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
				className=""
			>
				{noticias?.map((noticia, index) => (
					<>
						{noticia.midia !== null && (
							<SwiperSlide key={noticia}>
								<a
									href={noticia.tituloUrl}
									target="_blank"
									className="text-decoration-none"
									rel="noreferrer"
								>
									<div className="mask rounded"></div>
									<MDBCard
										className="border-0"
										style={{
											backgroundImage:
												"linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)), url('" +
												noticia?.midia +
												"')",
											backgroundSize: "cover",
											width: 300,
											height: 300,
										}}
									>
										<MDBCardBody
											className="text-white p-3"
											style={{
												position: "absolute",
												bottom: 5,
											}}
										>
											<p className="m-0">{noticia.titulo}</p>
											<p className="small m-0">
												<MDBIcon far icon="clock" />{" "}
												{new Date(noticia.dataCadastro).toLocaleDateString(
													"pt-br",
													{
														year: "numeric",
														month: "numeric",
														day: "numeric",
													}
												)}
											</p>
										</MDBCardBody>
									</MDBCard>
								</a>
							</SwiperSlide>
						)}
					</>
				))}
			</Swiper>
		</>
	);
}
