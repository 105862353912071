import React from "react";

import { MDBRow, MDBCol, MDBBtn } from "mdbreact";

export default function Eventos({ evento }) {
	return (
		<MDBRow className="evento">
			<MDBCol size={12}>
				<hr />
			</MDBCol>
			{evento?.Configuracoes?.Imagem?.Media !== null && (
				<MDBCol md={5} size={12} className="text-center">
					<img
						src={evento?.Configuracoes?.Imagem?.Media?.PublicUrl}
						className="img-fluid shadow rounded"
					/>
				</MDBCol>
			)}
			<MDBCol
				md={evento?.Configuracoes?.Imagem?.Media !== null ? 7 : 12}
				size={12}
				className="text-center text-md-left pt-3 pt-md-0"
			>
				<h4 className="font-weight-bold">{evento?.Title}</h4>
				<p className="m-0">{evento?.Configuracoes?.DataEventoText?.Value}</p>
				<p>{evento?.Configuracoes?.Local?.Value}</p>
				<div
					dangerouslySetInnerHTML={{
						__html: evento?.Configuracoes?.InformacoesEvento?.Value,
					}}
				></div>
				{evento?.Configuracoes?.LinkBotao?.Value !== null &&
					evento?.Configuracoes?.LinkBotao?.Value !== "" && (
						<MDBBtn
							block
							href={evento?.Configuracoes?.LinkBotao?.Value}
							target="_blank"
						>
							SAIBA MAIS
						</MDBBtn>
					)}
			</MDBCol>
		</MDBRow>
	);
}
