import React, { useState } from "react";

// Imports Material Design
import "./../../src/assets/mdbreact/scss/mdb-free.scss";
import "bootstrap-css-only/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import SEO from "../components/SEO/SEO";
import { MDBContainer, MDBRow, MDBCol, MDBBox } from "mdbreact";

import Noticias from "../components/Noticias/Noticias.js";
import Eventos from "../components/Eventos/Eventos.js";
import Footer from "../components/Footer/footer";

import bg1 from "../assets/images/bgs/bg-1.png";
import bg2l from "../assets/images/bgs/bg-2-l.png";
import bg2r from "../assets/images/bgs/bg-2-r.png";
import bg3l from "../assets/images/bgs/bg-3-l.png";
import bg3r from "../assets/images/bgs/bg-3-r.png";
import selo from "../assets/images/imgs/selo.png";

export default function index() {
	return (
		<>
			<img
				src={bg1}
				className="img-fluid d-block bg-item bg-item-1"
				aria-hidden="true"
			/>
			<img
				src={bg2l}
				className="img-fluid d-block bg-item bg-item-2-l"
				aria-hidden="true"
			/>
			<img
				src={bg2r}
				className="img-fluid d-block bg-item bg-item-2-r"
				aria-hidden="true"
			/>
			<img
				src={bg3l}
				className="img-fluid d-block bg-item bg-item-3-l"
				aria-hidden="true"
			/>
			<img
				src={bg3r}
				className="img-fluid d-block bg-item bg-item-3-r"
				aria-hidden="true"
			/>
			<SEO
				title="Bicentenário da Imigração Alemã"
				description="O Bicentenário da Imigração Alemã marca os duzentos anos da vinda
				dos imigrantes alemães para o Rio Grande do Sul e ocorrerá em 25
				de julho de 2024.
				A Universidade Feevale, atenta a importância da imigração
				na região, organiza uma série de atividades em 2022, 2023 e 2024,
				com temas ligados ao Bicentenário da Imigração Alemã, contemplando
				imigração, migração e direitos humanos, sob o prisma das mais
				diferentes áreas do conhecimento."
			/>
			<MDBContainer>
				<MDBRow>
					<MDBCol className="text-center pt-5 pb-5">
						<div className="embed-responsive embed-responsive-16by9">
							<iframe
								className="embed-responsive-item"
								src="https://www.youtube.com/embed/AjWfY7SnMBI"
							></iframe>
							<iframe
								width="1349"
								height="496"
								src="https://www.youtube.com/embed/ZXGQahMoCmM?autoplay=1&mute=1&loop=1&playlist=ZXGQahMoCmM"
								title="Bicentenário da Imigração Alemã"
								frameborder="0"
								allow="autoplay; encrypted-media;"
								allowfullscreen
							></iframe>
						</div>
					</MDBCol>
				</MDBRow>
				<MDBRow className="pt-5 pb-5">
					<MDBCol md="4" size="12" className="float-center">
						<img src={selo} className="img-fluid" />
					</MDBCol>
					<MDBCol
						md="8"
						size="12"
						className="text-black text-center text-md-left mt-3"
					>
						<h1 style={{ fontSize: 45 }} className="font-weight-bold">
							Bicentenário da
							<br /> Imigração Alemã
						</h1>
						<p>
							O Bicentenário da Imigração Alemã marca os duzentos anos da vinda
							dos imigrantes alemães para o Rio Grande do Sul e ocorrerá em 25
							de julho de 2024.
							<br />
							<br /> A Universidade Feevale, atenta a importância da imigração
							na região, organiza uma série de atividades em 2022, 2023 e 2024,
							com temas ligados ao Bicentenário da Imigração Alemã, contemplando
							imigração, migração e direitos humanos, sob o prisma das mais
							diferentes áreas do conhecimento.
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow className="pt-5 pb-4 text-center text-md-left">
					<MDBCol>
						<h1 className="font-weight-bold">Eventos</h1>
						<p className="mb-0">
							Confira os eventos e oficinas comemorativas
							<br />
							do Bicentenário da imigração alemã.
						</p>
					</MDBCol>
				</MDBRow>
				<Eventos />
			</MDBContainer>
			<MDBBox className="bg-noticias py-4">
				<MDBContainer className="py-5">
					<MDBRow>
						<MDBCol className="text-center text-md-left">
							<h2 className="font-weight-bold">Notícias</h2>
							<p>
								Acompanhe as notícias e novidades relacionadas
								<br /> ao bicentenário da Imigração Alemã
							</p>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol>
							<Noticias />
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBBox>
			<MDBContainer className="text-center text-md-left pb-5">
				<MDBRow>
					<MDBCol className="py-5">
						<h1 className="font-weight-bold">Comissão organizadora</h1>
						<p style={{ fontSize: "16px" }}>
							Homologada pelo Reitor da Universidade Feevale, a Comissão do
							Bicentenário da Imigração Alemã da Universidade Feevale,
							responsável pela articulação entre o tema e as áreas de ensino,
							pesquisa e extensão é a seguinte:
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<a href="http://lattes.cnpq.br/5062841860136165" target="_blank">
							Alexandra Marcella Zottis
						</a>
						<p>
							Docente dos Cursos de Turismo e Gastronomia, Integrante do
							Conselho Municipal de Turismo de Campo Bom, Integrante do Conselho
							Municipal de Turismo de Novo Hamburgo, Integrante do Conselho
							Municipal de Turismo de Nova Petrópolis
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<a href="http://lattes.cnpq.br/4885102539610284" target="_blank">
							Ana Carolina Kayser
						</a>
						<p>
							Professora do Curso de Administração, Representante da área de
							pesquisa da Universidade Feevale, Responsável pela Gestão
							Administrativa e Assessoria – PROPPEX
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<a href="http://lattes.cnpq.br/7837979692837308" target="_blank">
							Cristina Ennes da Silva
						</a>
						<p>
							Professora dos Cursos de Artes Visuais, História, Letras e
							Pedagogia, Coordenadora dos Cursos de Artes Visuais, História,
							Letras e Pedagogia, Professora do PPG em Processos e Manifestações
							Culturais
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<a href="http://lattes.cnpq.br/1284376149723016" target="_blank">
							Luciane Iwanczuk Steigleder
						</a>
						<p>
							Representante da área de Extensão da Universidade Feevale,
							Responsável pelo Núcleo de Extensão Universitária – PROPPEX
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<a href="http://lattes.cnpq.br/1754314898767435" target="_blank">
							Luis Henrique Rauber
						</a>
						<p>
							Professor dos Cursos de Administração e Publicidade e Propaganda ,
							Diretor Acadêmico do Museu Nacional do Calçado, Diretor do
							Instituto de Ciências Humanas e Sociais, Integrante da Comissão
							Estadual do Bicentenário da Imigração Alemã no Brasil – membro
							suplente, Representante da Feevale na Comissão Oficial dos
							Festejos dos 200 anos da Imigração Alemã no Brasil, de NH
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<a href="http://lattes.cnpq.br/5839716126532583" target="_blank">
							Marshal Becon Lauzer
						</a>
						<p>
							Professor Design, Design Gráfico e Moda, Coordenador dos Cursos de
							Design e Moda
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<a href="http://lattes.cnpq.br/0815974008264471" target="_blank">
							Roswithia Weber
						</a>
						<p>
							Professora no Curso de História, Professora no PPG em Processos e
							Manifestações Culturais, Integrante da Comissão Estadual do
							Bicentenário da Imigração Alemã no Brasil – membro titular
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						Para entrar em contato com a comissão, mande um e-mail para{" "}
						<a hreaf="mailto:bicentenario2024@feevale.br">
							bicentenario2024@feevale.br
						</a>
						.
					</MDBCol>
				</MDBRow>
			</MDBContainer>
			<Footer/>
		</>
	);
}
